export enum Spec {
  AllTabDisabled = 'specs.siteSearch.AllTabDisabled',
  AppControllerLayoutFix = 'specs.siteSearch.AppControllerLayoutFix',
  HideEmptyTabs = 'specs.siteSearch.HideEmptyTabs',
  NewResultsPerPageDefaultValue = 'specs.siteSearch.NewResultsPerPageDefaultValue',
  ProGallery = 'specs.siteSearch.ProGallery',
  RemoveDuplicateControllers = 'specs.siteSearch.RemoveDuplicateControllers',
  SuggestionsNoDelay = 'specs.siteSearch.SuggestionsNoDelay',
  TrendingItems = 'specs.siteSearch.TrendingItems',
  UpdatedSettingsUX = 'specs.siteSearch.UpdatedSettingsUX',
  UseWarmupData = 'specs.siteSearch.UseWarmupData',
  SearchQuerySync = 'specs.siteSearch.SearchQuerySync',
  UpdatedPaginationUX = 'specs.siteSearch.UpdatedPaginationUX',
  RemoveManagingAppDefId = 'specs.siteSearch.RemoveManagingAppDefId',
  TrimMultilingualUrl = 'specs.siteSearch.TrimMultilingualUrl',
  MobileSettings = 'specs.siteSearch.MobileSettings',
  DisableAllTabByDefault = 'specs.siteSearch.DisableAllTabByDefault',
}
